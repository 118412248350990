<template>
  <div id="leads">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="content_title">
          <h2>{{ $t('menu.leads') }}</h2>

          <p></p>
        </div>

        <div class="table_content">
          <div class="top">
            <div class="select">
              <el-form>
                <AccountNumber :label="$t('lead.ibAccount')" @accountCallback="queryLeads"></AccountNumber>
              </el-form>
            </div>

            <ul class="leads_item">
              <li class="white_button li_button active" v-on:click="resetNormalPage" data-testid="resetNormalPage">
                {{ $t('lead.demoAccounts') }}
              </li>

              <li class="white_button li_button" v-on:click="resetDemoPage" data-testid="resetDemoPage">
                {{ $t('lead.header') }}
              </li>
            </ul>
          </div>

          <ul class="table_box_item">
            <li class="active">
              <div class="table_box">
                <el-table :data="displayDemoLeads" style="width: 100%">
                  <el-table-column :label="$t('common.keys.DATE')" min-width="120">
                    <template slot-scope="scope">
                      <span>{{ scope.row.create_time | date('DD/MM/YYYY') }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column prop="real_name" :label="$t('common.keys.NAME')" min-width="120"></el-table-column>

                  <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="120"></el-table-column>

                  <el-table-column :label="$t('common.keys.PHONE')" min-width="120">
                    <template slot-scope="scope">
                      <span>{{ scope.row.phone_num || '-' }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('common.keys.ACCTYPE')" min-width="120">
                    <template slot-scope="scope">{{ $config.accountTypeMaps[scope.row.accountMT4Type] }}</template>
                  </el-table-column>

                  <el-table-column :label="$t('common.keys.PLATFORM')" min-width="100">
                    <template slot-scope="scope">
                      <span>{{ scope.row.platform | formatPlatform() }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('common.keys.BASECURRENCY')" min-width="120">
                    <template slot-scope="scope">
                      <span>{{ scope.row.apply_currency | formatUppercase() }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <!-- 分页 -->

              <pagination
                ref="demoPagination"
                v-bind:table-data="demoLeads"
                v-bind:display-data.sync="displayDemoLeads"
              ></pagination>
            </li>

            <li>
              <div class="table_box">
                <el-table :data="displayNormalLeads" style="width: 100%">
                  <el-table-column :label="$t('common.keys.DATE')" min-width="120">
                    <template slot-scope="scope">
                      <span>{{ scope.row.create_time | date('DD/MM/YYYY') }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column prop="real_name" :label="$t('common.keys.NAME')" min-width="120"></el-table-column>

                  <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="120"></el-table-column>

                  <el-table-column :label="$t('common.keys.PHONE')" min-width="120">
                    <template slot-scope="scope">
                      <span>{{ scope.row.phone_num || '-' }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <!-- 分页 -->

              <Pagination
                ref="normalPagination"
                v-bind:table-data="normalLeads"
                v-bind:display-data.sync="displayNormalLeads"
              ></Pagination>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import AccountNumber from '@/components/form/AccountNumber';
import { apiLeads } from '@/resource';

export default {
  components: { Pagination, AccountNumber },
  data() {
    return {
      userID: this.$store.state.common.CUID,
      leads: [],
      demoLeads: [],
      displayDemoLeads: [],
      normalLeads: [],
      displayNormalLeads: []
    };
  },
  mounted() {
    // 表格切换效果
    $('.leads_item li').click(function () {
      // 获取当前索引
      var index = $(this).index();
      $(this).addClass('active').siblings('li').removeClass('active');
      $('.table_box_item>li:eq(' + index + ')')
        .addClass('active')
        .siblings('li')
        .removeClass('active');
    });
  },
  methods: {
    queryLeads() {
      apiLeads({ userId: this.userID }).then(resp => {
        this.leadsLoading = false;

        if (resp.data.code == 305) {
          this.leads = [];
          this.updateLeads();
        } else {
          this.leads = resp.data;
          this.updateLeads();
        }
      });
    },
    updateLeads() {
      this.demoLeads = [];
      this.normalLeads = [];
      this.leads.forEach(element => {
        if (element.affid != this.accountID) {
          return;
        }
        if (element.website_user_type == '1' && element.accountMT4Type) {
          this.demoLeads.push(element);
          return;
        }
        if (element.website_user_type == '8') {
          this.normalLeads.push(element);
        }
      });
      this.resetDemoPage();
      this.resetNormalPage();
    },
    resetDemoPage() {
      this.$refs.demoPagination.resetPage();
    },
    resetNormalPage() {
      this.$refs.normalPagination.resetPage();
    }
  },
  watch: {
    accountID() {
      this.updateLeads();
    }
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/leads.scss';
</style>
